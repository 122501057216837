import React, { Suspense, useState, useEffect } from 'react';
import Head from 'next/head';
import MenuMain from '../components/HomeComponents/MenuMain'
import Footer from '../components/HomeComponents/Footer'
import { Layout } from 'antd';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import to from 'await-to-js';
import useBaseHooks from 'themes/hooks/BaseHooks';


const HomeLayout = (props: any) => {
  const {setStore} = useBaseHooks();
  const {metaData = {}, data = {} } = props

    const updateSize = () => {
      const mobile = window.innerWidth < 992;
      setStore("isMobile",mobile);
    }
    
    useEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      // return () => window.removeEventListener('resize', updateSize);
    }, [])

    return (<React.Fragment>
      <Head>
        <title>{metaData.title || data.siteTitle}</title>
        <meta property="og:url" content={`${data.siteUrl}${metaData.url || ""}`} />
        <meta property="og:type" content={metaData.type || "website"} />
        <meta property="og:title" content={metaData.title || data.siteTitle} />
        <meta property="og:description" content={metaData.description || data.siteDescription} />
        <meta property="og:image" content={metaData.image || data.logo} />

      </Head>
      <div id="home">
        <main>
          <Layout>
            <MenuMain />
            <div className="content">
              {props.children}
            </div>
            <Footer />
          </Layout>
        </main>
      </div>
    </React.Fragment>
    );
  }


export default HomeLayout;