import { Menu, Row, Col, Button, Layout, Drawer } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import { getSidebarSelecteds } from "themes/route";
import _routes from "themes/_routes.json";
import useBaseHooks from "themes/hooks/BaseHooks";
import { useState } from "react";
const MenuMobie = (props: any) => {
  const { t, redirect, getPublicRuntimeConfig, router } = useBaseHooks({
    lang: ["menu"],
  });
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  let publicRuntimeConfig = getPublicRuntimeConfig();
  let { LOGO = "" } = publicRuntimeConfig;
  const getRouteSidebar = () => {
    const routePath = router.pathname;
    const routeData: any = _routes;
    for (let routeName in routeData) {
      let routeElement = routeData[routeName];
      if (!routeElement.action) continue;
      if (routeElement.action.substr(5) === routePath) return routeData[routeName].sidebar;
    }
  };
  const currentRouteSidebar = getRouteSidebar();
  return (
    <>
      <Row justify="space-between" align="middle" className="menuMobile-header">
        <Col>
          <Button onClick={() => setVisibleDrawer(!visibleDrawer)} type="link">
            <MenuFoldOutlined />
          </Button>
        </Col>
        <Col>
          <a onClick={() => redirect("frontend.landing.index")}>
            <img src={LOGO}></img>
          </a>
        </Col>
      </Row>

      <Drawer
        visible={visibleDrawer}
        placement="left"
        onClose={() => setVisibleDrawer(false)}
        maskClosable
        closable={false}
        destroyOnClose={true}
        bodyStyle={{
          padding: 0,
        }}
        className="drawer"
      >
        <Menu mode="inline" selectedKeys={[currentRouteSidebar]}>
          <Menu.Item key="frontend.landing.index">
            <a onClick={() => redirect("frontend.landing.index")}>
              {t("frontend.home.webname")}
            </a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href="#feature">
              {t("frontend.home.feature")}
            </a>
          </Menu.Item>
          <Menu.Item key="3">
            <a href="#aboutMeMain">
              {t("frontend.home.about")}
            </a>
          </Menu.Item>
          <Menu.Item key="4">
            <a href="#priceTablePro">
              {t("frontend.home.price")}
            </a>
          </Menu.Item>
          <Menu.Item key="frontend.landing.customer">
            <a onClick={() => redirect("frontend.landing.customer")}>
              {t("frontend.home.customer")}
            </a>
          </Menu.Item>
          <Menu.Item key="frontend.landing.support">
            <a onClick={() => redirect("frontend.landing.support")}>
              {t("frontend.home.support")}
            </a>
          </Menu.Item>
          <Menu.Item key="frontend.landing.contact">
            <a onClick={() => redirect("frontend.landing.contact")}>
              {t("frontend.home.contact")}
            </a>
          </Menu.Item>
          <Menu.Item key="8">
            <a href="https://shop.fiup.vn/admin/login">
              <Button type="primary">Đăng nhập</Button>
            </a>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default MenuMobie;
