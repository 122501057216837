import React, { useState } from "react";
import { Menu, Row, Col, Button, Form, Input, Select } from "antd";
import to from "await-to-js";
import RegisterService from "themes/services/api/RegisterService";
import useBaseHook from "themes/hooks/BaseHooks";

const Footer = (props: any) => {
// Post API
const registerService = new RegisterService();
const { t, notify, redirect, router, getPublicRuntimeConfig } = useBaseHook();
let publicRuntimeConfig = getPublicRuntimeConfig();
let {WHITE_LOGO = "", LOGO = ""} = publicRuntimeConfig;
const [loading, setLoading] = useState(false);
const [form] = Form.useForm();
//submit form
const onFinish = async (values: any): Promise<void> => {
  setLoading(true);
  let [error, result]: any[] = await to(registerService.createEmail(values));

  setLoading(false);

  if (error) {
  return notify(t(`errors:${error.code}`), t(error.message), "error");
  }
  form.resetFields();
  notify(t("message.recordCreatedEmail")); //Hiển thị thông báo khi thành công
  //redirect("frontend.landing.trialSuccess") //Trường hợp dữ liệu hơp lệ sẽ di chuyển đến trang này.
  };

  return (
  <>
    <div id="contactTestStart">
      <Row>
        <Col xs={24} md={24} lg={24} xl={24}>
        <div className="contentContactTestStart">
          <h1>Hãy dùng thử ngay hôm nay</h1>
          <h2>Bạn là người đầu tư thông minh hay nhường lại cơ hội cho người khác?</h2>
          <Button onClick={()=> redirect("frontend.landing.trial")}>
            Dùng thử
          </Button>
        </div>
        </Col>
      </Row>
    </div>
    <div id="FooterMain">
      <div className="container">
        <div className="mt50">
          <Row>
            <Col xs={24} md={16} lg={10} xl={11}>
            <div className="itemFooterPro">
              <a onClick={()=> redirect("frontend.landing.index")}>
                <img src={LOGO}></img>
              </a>
              <p>
                FIUP là công ty tiên phong, đầu tiên tại Việt Nam áp dụng công nghệ AI, IoT, dữ liệu lớn vào ngành dịch
                vụ như spa, salon, thẩm mỹ viện, cửa hàng nha khoa... để xây dựng mô hình cung cấp dịch vụ kiểu mới 4.0
                nhằm mang lại trải nghiệm khác biệt cho khách hàng tại cửa hàng từ lúc tìm hiểu sử dụng dịch vụ cho tới
                khi trở thành khách hàng thân thiết.
              </p>
              <p>FIUP giúp cho các cửa hàng tăng doanh thu giảm chi phí tối đa.</p>
            </div>
            </Col>
            <Col xs={24} md={8} lg={6} xl={6}>
            <div className="menuFooterMqn">
              <h1>trợ giúp</h1>
              <Menu>
                <Menu.Item>Trung tâm trợ giúp</Menu.Item>
                <Menu.Item>Quy định sử dụng</Menu.Item>
                <Menu.Item>Chính sách bảo mật</Menu.Item>
                <Menu.Item>Liên hệ</Menu.Item>
              </Menu>
            </div>
            </Col>
            <Col xs={24} md={24} lg={8} xl={7}>
            <div className="menuFooterMqn">
              <h1>hãy để chúng tôi giúp bạn tăng doanh thu</h1>
              <div className="itemContactInfo">
                <img src="/static/img/phone.svg" alt="" />
                <p>024 6291 9536</p>
              </div>
              <div className="itemContactInfo">
                <img src="/static/img/email.svg" alt="" />
                <p>hotro@fiup.vn</p>
              </div>
              <div className="itemContactInfo">
                <img src="/static/img/pin.svg" alt="" />
                <p>Tầng 10, Tòa nhà Zentower, 12 Khuất Duy Tiến, Thanh Xuân, Hà Nội</p>
              </div>
            </div>
            </Col>
          </Row>
        </div>

        <div className="copyrightFooter">
          <Row>
            <Col xs={24} md={14} lg={15} xl={13}>
            <div className="copyright">
              <p>
                Copyright © 2020 fiup.vn - Nền tảng quản lý và bán hàng được
                sử dụng nhiều nhất Việt Nam
              </p>
            </div>
            </Col>
            <Col xs={24} md={8} lg={9} xl={11}>
            <div className="iconSocial">
              <img src="/static/img/facebook.svg" alt="" />
              <img src="/static/img/youtube.svg" alt="" />
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </>
  );
  };

  export default Footer;