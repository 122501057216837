import { useState } from "react";
import { Menu, Row, Col, Button } from "antd";
import MenuMobie from "./MenuMobie";
import useScroll from "themes/hooks/Scroll";
import { route as makeUrl } from "themes/route";
import Link from "next/link";
import useBaseHooks from "themes/hooks/BaseHooks";


const MenuMain = (props: any) => {
  const { t, redirect, getStore, getPublicRuntimeConfig } = useBaseHooks({ lang: ["menu"] });
  let publicRuntimeConfig = getPublicRuntimeConfig();
  let {WHITE_LOGO = "", LOGO = ""} = publicRuntimeConfig;
  const [isTop, setIsTop] = useState(true);
  let isMobile = getStore("isMobile");

  useScroll(({ xOffset, yOffset }: any) => {
    if (yOffset == 0) {
      setIsTop(true);
    } else setIsTop(false);
  });

  return (
    <>
      <div id="headerMenu">
        {!isMobile ? (
          <div className={`menuPc ${isTop ? "transparent" : "whitebg"}`}>
            <div className="container">
              <Row>
                <Col md={21} lg={22} xl={22}>
                  <a onClick={() => redirect("frontend.landing.index")}>
                    <img
                      src={isTop ? WHITE_LOGO : LOGO}
                      className="headerMenu-logo"
                    ></img>
                  </a>
                  <Menu mode="horizontal" className="customerMenu">
                    <Menu.Item key="2">
                      <a href="#feature">
                        {t("frontend.home.feature")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <a href="#aboutMeMain">
                        {t("frontend.home.about")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="4">
                      <a href="#priceTablePro">
                        {t("frontend.home.price")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="5">
                      <a onClick={() => redirect("frontend.landing.customer")}>
                        {t("frontend.home.customer")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <a onClick={() => redirect("frontend.landing.support")}>
                        {t("frontend.home.support")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="8">
                      <a onClick={() => redirect("frontend.landing.contact")}>
                        {t("frontend.home.contact")}
                      </a>
                    </Menu.Item>
                  </Menu>
                </Col>
                <Col md={3} lg={2} xl={2}>
                  <a href="https://shop.fiup.vn/admin/login">
                    <Button className="btnLoginMenu">Đăng nhập</Button>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <MenuMobie />
        )}
      </div>
    </>
  );
};

export default MenuMain;
