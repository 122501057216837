import BaseApi from './BaseService'

class Register extends BaseApi {
  constructor(context?: Context){
    super(context)
    this.name = 'registers';
  }

  async createEmail(data: any){
    const url = this.route(`api.${this.name}.registerEmail`,data).href
    return await this.Request.post(url, data)
  }
}

export default Register